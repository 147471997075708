import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import FBAPrep from 'images/blog-images/fba-prep-fba-vs-fbm.png';
import EcommerceFulfillment from 'images/blog-images/ecommerce-fulfillment-fba-vs-fbm.png';
import FBAvsFBM from 'images/blog-images/fba-vs-fbm.png';
import FBAvsFBMFeatured from 'images/featured-image/fba-vs-fbm-featured.png';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query FBAvsFBM {
      file(relativePath: { eq: "fba-vs-fbm.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="fba vs fbm: A full comparison for ecommerce sellers "/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="FBA vs FBM: A Full Comparison for eCommerce Sellers | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-fba-vs-fbm" })}
        htmlAttributes={{
          lang: 'en',
        }}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "FBA vs FBM: A Full Comparison for eCommerce Sellers | Shipkoo",
              content: intl.formatMessage({ id: "blog-post-meta-title-fba-vs-fbm" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: FBAvsFBMFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="fba-vs-fbm-a-full-comparison-for-ecommerce-sellers"
        title={intl.formatMessage({ id: "fba-vs-fbm-head-title" })}
        date="2021-05-25"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
          <h5 className="no-top">
          		<strong>
                {intl.formatMessage({ id: "fba-vs-fbm-head-title1" })}
          		</strong>
          	</h5>
            <p>
                {intl.formatMessage({ id: "fba-vs-fbm-content1" })}
            </p>
          	<h5>
          		<strong>
                {intl.formatMessage({ id: "fba-vs-fbm-head-title2" })}
          		</strong>
          	</h5>
            <p>
                {intl.formatMessage({ id: "fba-vs-fbm-content2" })}
            </p>

            <h5>
              <strong>
                {intl.formatMessage({ id: "fba-vs-fbm-head-title3" })}
              </strong>
            </h5>

            <OrderedList>
              <li>
                <p><strong>{intl.formatMessage({ id: "fba-vs-fbm-head-title4" })}</strong></p>
                {intl.formatMessage({ id: "fba-vs-fbm-content3" })}
              </li>
              <li>
                <p><strong>{intl.formatMessage({ id: "fba-vs-fbm-head-title5" })}</strong></p>
                {intl.formatMessage({ id: "fba-vs-fbm-content4" })}
              </li>
              <li>
                <p><strong>{intl.formatMessage({ id: "fba-vs-fbm-head-title6" })}</strong></p>
                {intl.formatMessage({ id: "fba-vs-fbm-content5" })}
              </li>
            </OrderedList>

            <Image src={FBAvsFBM} className="" alt="fba vs fbm"/>

            <h5>
              <strong>
                {intl.formatMessage({ id: "fba-vs-fbm-head-title7" })}
              </strong>
            </h5>    

            <p>
                {intl.formatMessage({ id: "fba-vs-fbm-content6" })}
            </p>

            <h5>
              <strong>
                {intl.formatMessage({ id: "fba-vs-fbm-head-title8" })}
              </strong>
            </h5>

            <OrderedList>
            	<li>
            		<p><strong>{intl.formatMessage({ id: "fba-vs-fbm-head-title9" })}</strong></p>
            		{intl.formatMessage({ id: "fba-vs-fbm-content7" })}
            	</li>
            	<li>
            		<p><strong>{intl.formatMessage({ id: "fba-vs-fbm-head-title10" })}</strong></p>
            		{intl.formatMessage({ id: "fba-vs-fbm-content8" })}
            	</li>
            	<li>
            		<p><strong>{intl.formatMessage({ id: "fba-vs-fbm-head-title11" })}</strong></p>
            		{intl.formatMessage({ id: "fba-vs-fbm-content9" })}
            	</li>
            </OrderedList>

            <Image src={EcommerceFulfillment} alt="ecommerce fulfillment" />

            <h5>
              <strong>
                {intl.formatMessage({ id: "fba-vs-fbm-head-title12" })}
              </strong>
            </h5>

            <p>
                {intl.formatMessage({ id: "fba-vs-fbm-content10" })}
            </p>
            <p>
                {intl.formatMessage({ id: "fba-vs-fbm-content11" })}
            </p>
            <p>
                {intl.formatMessage({ id: "fba-vs-fbm-content12" })}
            </p>
            <p>
                {intl.formatMessage({ id: "fba-vs-fbm-content13" })}
            </p>
            <p>
                {intl.formatMessage({ id: "fba-vs-fbm-content14" })}
            </p>

            <Image src={FBAPrep} alt="fba prep and forwarding" />

            <BottomShare>
              <div className="column-half">
                <p>{intl.formatMessage({ id: "amazon-fba-fee-content19" })}</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>{intl.formatMessage({ id: "more-from-shipkoo-text" })}</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;